import React from 'react';
import { buildImageObj, linkRendererWithExternalToBlank } from '../../lib/helpers';
import imageUrlFor from '../../lib/image-url';

const BlockContent = require('@sanity/block-content-to-react');

const About = props => {
  const { data: { text, name, image } } = props;

  const imageUrl = imageUrlFor(buildImageObj(image));

  return (
    <section className="about">
      <div className="about__content">
        <div className="about__header">
          <h2 className="about__heading">{name}</h2>
          <img
            className="about__image"
            src={imageUrl
              .width(300)
              .height(300)
              .quality(70)
              .fit('crop')
              .format('jpg')
              .url()}
            alt={'Bilde av meg....'}
          />
        </div>
        <div className="about__text">
          <BlockContent serializers={{
            marks: {
               link: linkRendererWithExternalToBlank,
            },
         }} blocks={text} />
        </div>
      </div>
    </section>
  );
};

export default About;
