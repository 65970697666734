import React from 'react';
import { graphql } from 'gatsby';
import Layout from './layout';
import SEO from '../components/seo';
import { getPageModuleComponent, buildImageObj } from '../lib/helpers';
import imageUrlFor from '../lib/image-url';

export const query = graphql`
  query PageQuery($pageId: String) {
    page: sanityPage(id: { eq: $pageId }) {
      _rawModules(resolveReferences: { maxDepth: 6 })
      _rawOgimage
    }
  }
`;

const Page = props => {
  const {
    pageContext: { name, description },
    data: {
      page: { _rawModules: modules, _rawOgimage: image },

    },
    location,
  } = props;

  const modulesCounterArr = [];

  const hasImage = !!(image && image.asset);
  const oGImage = hasImage
    ? imageUrlFor(buildImageObj(image))
        .auto('format')
        .width(1000)
        .height(700)
        .fit('clip')
    : null;

  return (
    <Layout location={location}>
      <SEO
        title={name}
        description={description}
        image={hasImage ? oGImage : ''}
      />
      {modules.map(m => {
        if (!modulesCounterArr[m._type]) {
          modulesCounterArr[m._type] = 1;
        } else {
          modulesCounterArr[m._type] += 1;
        }
        const module = getPageModuleComponent(m, modulesCounterArr[m._type], props);
        return module;
      })}
    </Layout>
  );
};

export default Page;
