import React from 'react';
import Header from '../components/pageModules/header';
import About from '../components/pageModules/about';
import Projects from '../components/pageModules/projects';
import Quote from '../components/pageModules/quote';

export function cn(...args) {
  return args.filter(Boolean).join(' ');
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map(edge => edge.node);
}

export function getPageModuleComponent(m) {
  switch (m._type) {
    case 'header':
      return <Header id={`module-elevator-${m._key}`} key={`module-elevator-${m._key}`} data={m} />;
    case 'about':
      return <About id={`module-elevator-${m._key}`} key={`module-elevator-${m._key}`} data={m} />;
    case 'projects':
      return <Projects id={`module-elevator-${m._key}`} key={`module-elevator-${m._key}`} data={m} />;
    case 'quote':
      return <Quote id={`module-elevator-${m._key}`} key={`module-elevator-${m._key}`} data={m} />;
    default:
      return null;
  }
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function isLinkExternal(url) {
  if (typeof window === 'undefined') {
    return false;
  }

  const host = window.location.hostname;

  const linkHost = ((checkUrl) => {
    if (/^https?:\/\//.test(checkUrl)) {
      const parser = document.createElement('a');
      parser.href = checkUrl;

      return parser.hostname;
    }

    // Relative URL.
    return window.location.hostname;
  })(url);

  return host !== linkHost;
}

export function linkRendererWithExternalToBlank(props) {
  const external = isLinkExternal(props.mark.href);

  return (
    <a target={external ? '_blank' : '_self'} title={props.children} href={props.mark.href}>
      {props.children}
    </a>
  );
}

export function richTextRawRenderer(props) {
  const { node } = props;

  return (
    <div className="raw__content" dangerouslySetInnerHTML={{__html: node.content}}></div>
  );
}
