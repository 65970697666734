import React from "react";
// import { useStaticQuery, graphql } from 'gatsby';
// import Project from "../bits/project";
// import { mapEdgesToNodes } from "../../lib/helpers";

// const query = graphql`
//   query allProjectsQuery {
//     projects: allSanityProject(sort: { fields: priority, order: ASC }) {
//       edges {
//         node {
//           title
//           timespan
//           teaser
//           url
//           slug{
//             current
//           }
//           role
//           image: _rawImage
//         }
//       }
//     }
//   }
// `;

const Projects = props => {
  // let { projects } = useStaticQuery(query);
  // projects = mapEdgesToNodes(projects);

  return (
    <section id="projects" className="projects">
      <div className="projects__content">
        <div className="projects__header">
          <h2 className="projects__heading">{props.data.name}</h2>
          <p style={{ textAlign: "center", fontSize: "1.2rem" }}>
            Coming soon... <br />
            In the meantime, here are some of the stuff I{"'"}ve worked with:
          </p>
          {/* <div className='projects__wrapper'>
            {projects.map(p => <Project key={p._id} project={p} />)}
          </div> */}
        </div>
        <div className="projects__tags tags">
          <div className="tags__content">
            <span className="tag">Javascript</span>
            <span className="tag">Typescript</span>
            <span className="tag">ReactJS</span>
            <span className="tag">NodeJS</span>
            <span className="tag">NextJS</span>
            <span className="tag">React Native</span>
            <span className="tag">CSS</span>
            <span className="tag">SASS</span>
            <span className="tag">REST</span>
            <span className="tag">GraphQL</span>
            <span className="tag">NPM</span>
            <span className="tag">Git</span>
            <span className="tag">PostgreSQL</span>
            <span className="tag">MongoDB</span>
            <span className="tag">Neo4j</span>
            <span className="tag">Google Cloud</span>
            <span className="tag">Sanity</span>
            <span className="tag">Contentful</span>
            <span className="tag">Shopify</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
