import React from 'react';
import ReactRotatingText from 'react-rotating-text';
import { shuffle } from 'lodash';

const Header = props => {
  const { data: { text, subtext, adjectives } } = props;

  const firstpart = subtext.split('{adjectives}')[0];
  const secondpart = subtext.split('{adjectives}')[1];

  const items = shuffle(adjectives);

  return (
    <header className="header">
      <div className="header__content">
        <h1 className="header__title">{text}</h1>
        <p className="header__intro">— {firstpart} <ReactRotatingText color={'#FCFAF8'} typingInterval={90} emptyPause={500} pause={3000} items={items} /> {secondpart}</p>
        <div className="header__down-wrapper">
          <span className="header__down">&rsaquo;</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
