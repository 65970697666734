import React from 'react';

const Quote = props => {
  const { data: { text, by } } = props;

  return (
    <section className="quote">
      <div className="quote__content">
        <blockquote className="quote__text">
          <p>{text}</p>
          <cite>— {by}</cite>
        </blockquote>
      </div>
    </section>
  );
};

export default Quote;
